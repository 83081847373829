@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 85% 8%;
    --primary: 231 97% 60%;
    --primary-foreground: 0 0% 100%;
    --secondary: 240 85% 8%;
    --secondary-foreground: 0 0% 100%;
    --muted: 226 100% 97%;
    --muted-foreground: 240 85% 8%;
    --card: 0 0% 100%;
    --card-foreground: 240 85% 8%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 85% 8%;
    --accent: 226 100% 97%;
    --accent-foreground: 240 85% 8%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 100%;
    --border: 226 100% 97%;
    --input: 226 100% 97%;
    --ring: 231 97% 60%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 240 85% 8%;
    --foreground: 0 0% 100%;
    --primary: 231 97% 60%;
    --primary-foreground: 0 0% 100%;
    --secondary: 226 100% 97%;
    --secondary-foreground: 240 85% 8%;
    --muted: 240 85% 15%;
    --muted-foreground: 215 20.2% 65.1%;
    --card: 240 85% 8%;
    --card-foreground: 0 0% 100%;
    --popover: 240 85% 8%;
    --popover-foreground: 0 0% 100%;
    --accent: 231 97% 60%;
    --accent-foreground: 0 0% 100%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 100%;
    --border: 240 85% 15%;
    --input: 240 85% 15%;
    --ring: 231 97% 60%;
  }
}



@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground font-gilroy;
  }
}

/* Task Board Styles */
@layer components {
  .task-board {
    @apply grid grid-cols-3 gap-4 p-4;
  }

  .task-card {
    @apply bg-white shadow-md p-4 rounded-md mb-2;
  }

  .gantt-container {
    @apply p-4 bg-gray-100;
  }

  .timeline {
    @apply flex justify-between items-center;
  }

  .milestone {
    @apply bg-gray-100 p-4 rounded-md shadow-sm;
  }
}

#bpro_gantt {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 20px;
}

/* Add to your global CSS */
.gantt-chart-container {
  --row-height: 40px;
}

.gantt-chart-container .bar-wrapper {
  cursor: pointer;
}

.gantt-chart-container .bar-wrapper:hover {
  filter: brightness(95%);
}

/* Task list styles */
.gantt-chart-container .project {
  font-weight: 600;
  background-color: #f8fafc;
}

.gantt-chart-container .task-list-header {
  background-color: #f8fafc;
  font-weight: 500;
  color: #475569;
  height: var(--row-height);
  line-height: var(--row-height);
}

.gantt-chart-container .task-list-item {
  height: var(--row-height);
  line-height: var(--row-height);
  padding: 0 12px;
  display: flex;
  align-items: center;
}

/* Bar styles */
.gantt-chart-container .bar-task {
  height: 24px;
  border-radius: 4px;
  margin-top: 8px;
}

.gantt-chart-container .bar-task-project {
  height: 24px;
  border-radius: 4px;
  margin-top: 8px;
}

/* Grid styles */
.gantt-chart-container .grid-cell {
  border-color: #e2e8f0;
}

/* Better text contrast */
.gantt-chart-container .bar-label {
  color: #1e293b;
  font-weight: 500;
  font-size: 12px;
}

/* Scrollbar styles */
.gantt-chart-container ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.gantt-chart-container ::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.gantt-chart-container ::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 4px;
}

.gantt-chart-container ::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}